.App {
  text-align: center;

}

html {
  font-size: 100%;
}
 /* ################################################################## */
nav{
  background: linear-gradient(45deg, #0b2b6a, #2CC5CB);
  height: 7vh;
position: fixed;
width: 100%;
padding-right: 1vw;

}

nav ul {
  width: 100%;
  height: 7vh;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
nav a {
  padding: 2vh 20px ;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  transition: 0.3s;
  border-radius:0.2cm;
  padding: 1vh 1vw ;

 
}

nav a:hover{
  color: rgb(0, 46, 46);
  background: aqua;
  text-decoration: none;
  padding: 1vh 1vw ;
}
nav li:first-child{
  margin-right: auto;
} 

.sidebar{
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 300px;
background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: -10px 0 10px  rgb(0, 0, 0, 0.1);
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
}
.sidebar li {
  width: 100%;
}


.hideOnWindow{
  display: none;
}

.headerdiv{
  height: 7vh;
}



.App-logo {
  width: 4.2rem;
  height: auto; 
  background-size:contain;
  margin-right: 0.5vw;

}

.nav-logo {
 display: flex;
align-items: center;
justify-items: center;
height: 7vh;

}




.nav-logo h1 {
  margin-top:1vh;
  font-size: 1.8rem;

}




.WelcomeCon {
  height: 70vh;
  width: 100%;
  
  background-size:cover;
  background-position: center;

  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
  justify-content: center;
 }

.WelcomeCon h1  {
color: #fff;
font-size: 2.6rem;
}
.WelcomeCon p {
  color: #fff;
  font-size: 2rem;
  }


/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

  .Basic-detail {
    background-size:cover;
    height: 70vh;
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;

    
   }


 .company-detail {
width: 50%;
/* background: green; */
height: auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 3rem 0rem;
gap: 2rem ;

 }
 .company-detail h1 {
  color: rgb(0, 0, 0);

  text-align:start; 
  font-size: 2.5rem;

  width: 80%;
  font-weight: bold;
}
.company-detail h2 {
  color: rgb(0, 0, 0);

  text-align:start; 
  font-size: 1.3rem;
  width: 80%;
}
  
.company-detail2 {
  background: #ffffff;
  height: 52vh;
 width: 80%;
border-width:1ch;
display: flex;
align-items: center;
justify-content: center;
  border-radius: 20px;


 }
 .alvaro-Bernal{
  height: 45vh;
  width: 90%;
  border-radius:1cap;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;

 }

 .main-container {
  display: flex;
  width: 100%;

padding: 4rem 0rem;



}

.container{
  width: 50%;
  /* background: #000; */
  display: flex;
  justify-content: center;
  align-items: center;
}








.Border-line {
 width: 86%;
  padding: 1.8rem;
   background: linear-gradient(45deg, #b700ff, #00e1ff);
   border-radius: 3cap;
   box-shadow: 
     0 4px 8px rgba(0, 0, 0, 0.644), 
     0 6px 20px rgba(0, 0, 0, 0.774);
     justify-content: center;
     align-items: center;
     display: flex;
}

.main-container3 {

  background: #000000;
  width: 98%;
  border-radius: 3cap;
}

.main-container3 h1{
  color: #ffffff;
  text-align: start;
  margin:1.8rem  3rem ;
  font-size: 1.7rem;
  
  }
  .main-container3 p{
    color: #ffffff;
    text-align: start;
    margin:1.8rem  3rem ;
    font-size: 1.3rem;
    width: 80%;
    
    }



    .white-background {
      background-size:cover;
      align-items: center;
      display: flex; 
    justify-content: center;
    border-radius: 3cap;
    overflow: hidden;
    width:40vw;
    /* height: 50vh ; */
    
    padding: 10vh 2vw;
    }

.white-background p{
color: #ffffff;

 font-size: 1.6rem;
 width: 85%;
 text-align: start;
 font-weight:600;
   
 text-shadow: 0 4px 8px rgba(0, 0, 0, 0.644), 
 0 6px 20px rgba(0, 0, 0, 0.774);
   }









  


  .container4{
    margin: 20px;
  }
  .container4 h1 {
  text-align:start;

  font-size: 20px;

  }
  .container4 h2 {
    text-align:start;
   width: 80%;
    font-size: 0.8vw;
  
    }

   
  .Services {
    padding-top: 8vh;
    padding-bottom:8vh;
  }
  
    .Container5 {
      display: flex; 
      align-items: center;
      flex-direction: column; 

    }
    .Container5 h1 {
font-size: 1.5rem;
font-weight: bold;


    }
    .Main-Container5 {
   
      display: flex;
      align-content: center ;
      justify-content:center;
      flex-direction: row;
   
   margin-top: 5vh;
    }
.Service {
 
  border-width: 1px;
  border-style: solid;
  border-color: rgb(133, 133, 133);

  width: 20vw;
 margin: 2.8rem;
 background: #6d6d6d1a;
 border-radius: 20px;
 display: flex;
 flex-direction: column;
 transition: 0.3s;
}

.Service:hover {
  background: #ffffff1a;
  border-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.644), 
  0 6px 20px rgba(0, 0, 0, 0.774);
}
.imageStyel {
/* height: 15vh;
width: 15vh; */
height:9rem;
width:  9rem;
border-radius: 10cap;
background: #000000;

display: flex;

align-self: center;

flex-direction:column;
margin-top: 2vh;

justify-items: center;
}
.Service h1{
  font-size: 1.5rem;
  margin-top: 2vh;

}
.Service h2{
  font-size: 1.1vw;
  margin-top: 1vh;
  padding: 1rem 1.5rem;

}

    .Container3 {
    
      background: #005475; 

      display: flex; 
    
      align-items: center;
      flex-direction: column; 
    
     padding: 10vh 0vh;
    
    }
.Container3 h2{
  color: #fff;
font-size: 2rem;
}
   
.linediv {

  background: linear-gradient(45deg, #b700ff, #00e1ff);
  margin-top: 1vh;
  /* height:1vh;
  width: 10vw; */
  height:0.8rem;
  width: 12rem;
}
.profilePhoto {
  background: #000;
border-radius: 100%;
margin-top: 5vh;

  background-size:contain;

  background-repeat: no-repeat;

  height:12rem;
  width:  12rem;
}

.Container3 h1{
  margin-top: 3rem;
  background: linear-gradient(45deg, #00aaff, #00ff6c);
  border-radius:2cap;
  padding: .2vh 2vw;
  font-size: 1.2rem;
}

.Container3 p{
  color: #fff;
  margin-top: 3rem;

width: 70%;
font-size: 1.8rem;

}

.Container6 {
  width: 100%;
 
  padding: 4rem 0rem ;

  background: #00293a; 
  border-top-width:  .2rem;

  border-top-color: #ffffff;
  border-style: solid;
  display: flex;
align-items: center;

  }
  .Sub-Container6 {
      margin-left: 12vw;
        width: 22%;
      }
      .logoStyle{
        align-items:center;
        display: flex;
        justify-content: start;
      }
      
      .logoStyle a {
        font-size: 1.8rem;
      }
      .Sub-Container6 h1{
        font-size: 1.6rem;
        text-align: start;
        color: #005b88;
      }

.ContactInfo {
  padding-top: 2rem;
  height: 2.5rem;
  display: flex;
  align-items: center;

}
.ContactInfo h1{
  color: white;
  font-size: 1.1rem;
  font-weight: normal;
  margin-left: .5vw;
}
.IconStyle {
  color: #ffffff;
  font-size:  1.1rem;
}

.ContactInfo  a {
  color: white;
text-decoration: none;
font-weight: 600;
margin-left: .5vw;
font-size: 1.1rem;
}

.Sub-Container6 h3{
  font-size: 1.2rem;
  text-align: start;
  color: #208fc7;
  padding: 0rem  1rem;
  width: 90%;
  margin-top: 2rem;
}
.Sub-Container6 h4 {
  color: rgb(73, 73, 73);
  font-size: 0.8rem;
  text-align: start;
  margin-top: 2rem;
  margin-left: 1vw;
}

@media only screen and (min-width: 0px) and (max-width: 770px) {
  html {
    font-size: 45%;
  }

  .hideOnMobile{
    display: none;
  }
  .hideOnWindow{
    display: flex;

  }
/* ################################################################# */

  nav{
    background: linear-gradient(45deg, #0b2b6a, #2CC5CB);
    height: 5vh;
  position: fixed;
  width: 100%;
  padding-right: 1vw;
  
  }
  .headerdiv{
    height: 5vh;
  }
 nav ul {
  width: 100%;
  height: 5vh;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8vh;

  

}
  .linkClose{
    background-color: #0b2b6a;
    height: auto;
    width: 10vw;
    color: #fff;
    padding: 1vh 3vw ;
    /* margin-top: -0.2vh; */
    border-radius:0.2cm;
  }
  .link a {
  height: 7rem;
  width: 90%;
  margin-top: .5vh;
  padding: 3vh 10px ;
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 600;
 
  align-items: center;
  color: rgb(0, 0, 0);

  transition: 0.3s;
  background-color: #0b2b6a;
  border-radius:0.2cm;
  padding: 1vh 3vw ;
color: #fff;
 
}

.link a:hover{
  color: rgb(0, 46, 46);
  background: aqua;
  text-decoration: none;
  font-weight: 600;
  color: #000000;
  padding: 3vh 10px ;
}








  .Basic-detail {
    background-size:cover;
    height: 70vh;
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    flex-wrap: wrap;
   }
 
     .company-detail {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 3rem 0rem;
      gap: 2rem ;
      
       }
       .company-detail2 {
        background: #ffffff;
        height: 30vh;
       width: 60%;
      border-width:1ch;
      display: flex;
      align-items: center;
      justify-content: center;
        border-radius: 20px;
      
      
       }
       .alvaro-Bernal{
        height: 25vh;
        width: 80%;
        border-radius:1cap;
        background-size:cover;
        background-repeat: no-repeat;
        background-position: center;
      
       }
       .main-container {
        display: flex;
        width: 100%;
      
      padding: 4rem 0rem;
      
      flex-wrap: wrap;
      
      }

.main-container3 {

  background: #000000;
  width: 98%;
  /* height: 34vh; */
display: flex;
/* align-items: center; */
padding: 8rem 0rem;
justify-content: center;

flex-direction: column;
  border-radius: 3cap;
}

      .white-background {
        background-size:cover;
        align-items: center;
        display: flex; 
      justify-content: center;
      border-radius: 3cap;
      overflow: hidden;
      width:70vw;
      padding: 6vh 2vw;
      }
      
      .white-background p{
        color: #ffffff;
        
         font-size: 1.7rem;
         width: 85%;
         text-align: start;
         font-weight:600;
           
         text-shadow: 0 4px 8px rgba(0, 0, 0, 0.644), 
         0 6px 20px rgba(0, 0, 0, 0.774);
           }
        
      .container{
        width: 100%;
      display: flex;
      justify-content: center;
      padding: 2rem 0rem;
      }
      .Main-Container5 {
   
        display: flex;
        align-content: center ;
        justify-content:center;
        flex-direction: row;
     
     margin-top: 5vh;
     flex-wrap: wrap;
      }
      .Service {
 
        border-width: 1px;
        border-style: solid;
        border-color: rgb(133, 133, 133);
      
        width: 60vw;
       margin: 2.8rem;
       background: #6d6d6d1a;
       border-radius: 20px;
       display: flex;
       flex-direction: column;
       transition: 0.3s;
      }
      .Service h1{
        font-size: 2.5rem;
        margin-top: 2vh;
      
      }
      .Service h2{
        font-size: 2rem;
        margin-top: 1vh;
        padding: 1rem 1.5rem  2rem  1.5rem;
      
      }
      .Sub-Container6 {
        margin-left: 12vw;
          width: 30%;
        }
    
}
@media only screen and (min-height: 0px) and (max-height: 450px) {

  nav{
    background: linear-gradient(45deg, #0b2b6a, #2CC5CB);
    height: 8vh;
  position: fixed;
  width: 100%;
  padding-right: 1vw;
  
  }
  .headerdiv{
    height: 8vh;
  }
 nav ul {
  width: 100%;
  height: 8vh;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8vh;



}

.linkClose{
  background-color: #0b2b6a;
  height: auto;
  width: 7.5vw;
  color: #fff;
  padding: 0vh 2vw ;
  border-radius:0.2cm;
}

.link a {
  height: 5rem;
  width: 80%;
  margin-top: .5vh;
  padding: 3vh 10px ;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
 
  align-items: center;
  color: rgb(0, 0, 0);

  transition: 0.3s;
  background-color: #0b2b6a;
  border-radius:0.2cm;
  padding: 1vh 3vw ;
color: #fff;
margin-left: 1rem;
 
}



.Basic-detail {
  background-size:cover;
  height: 70vh;
  width: 100%;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;

  
 }
 .company-detail {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem 0rem;
  gap: 2rem ;
  
   }


}
@media only screen and (min-width: 770px) and (max-width: 1300px) {
  html {
    font-size: 55%;
  }

  .hideOnMobile{
    display: none;
  }


  .hideOnWindow{
    display: flex;
    margin-top: -0.5vh;
  }
  

 nav ul {
  width: 100%;
  height: 7vh;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1vh;

  

}



.linkClose{
  background-color: #0b2b6a;
  height: auto;
  width: 5vw;
  /* padding: 1rem; */
  padding: 1vh 1.5vw ;
  margin-top: -0.5vh;
  border-radius:0.2cm;
}
.link a {
/* height: 6vh; */
height: 6rem;
width: 90%;
margin-top: .5vh;
padding: 3vh 10px ;
text-decoration: none;
font-size: 1.8rem;
font-weight: 600;
margin-left: 1vw;

align-items: center;
color: rgb(0, 0, 0);

transition: 0.3s;
background-color: #0b2b6a;
border-radius:0.2cm;
padding: 1vh 3vw ;
color: #fff;

}

.link a:hover{
color: rgb(0, 46, 46);
background: aqua;
text-decoration: none;
font-weight: 600;
color: #000000;
padding: 3vh 10px ;
}





  .Basic-detail {
    background-size:cover;
    height: 60vh;
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
   }

  .company-detail {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3rem 0rem;
    gap: 1rem ;
    
     }
     .company-detail2 {
      background: #ffffff;
      height: 35vh;
     width: 60%;
    border-width:1ch;
    display: flex;
    align-items: center;
    justify-content: center;
      border-radius: 20px;
    
    
     }
     .alvaro-Bernal{
      height: 28vh;
      width: 80%;
      border-radius:1cap;
      background-size:cover;
      background-repeat: no-repeat;
      background-position: center;
    
     }
     .Border-line {
      width: 95%;
       padding: 1.4rem;
        background: linear-gradient(45deg, #b700ff, #00e1ff);
        border-radius: 3cap;
        box-shadow: 
          0 4px 8px rgba(0, 0, 0, 0.644), 
          0 6px 20px rgba(0, 0, 0, 0.774);
          justify-content: center;
          align-items: center;
          display: flex;
     }
     .main-container3 {

      background: #000000;
      width: 98%;
      /* height: 34vh; */
    display: flex;
    /* align-items: center; */
    padding: 6rem 0rem;
    justify-content: center;
    
    flex-direction: column;
      border-radius: 3cap;
    }
    
     .main-container3 {
     
       background: #000000;
       width: 98%;
       border-radius: 3cap;
     }


}
/* @media only screen and (min-heigth: 0px) and (max-heigth: 35px) {
  html {
    font-size: 70%;
  }
  nav{
    background: linear-gradient(45deg, #f13f09, #2CC5CB);
    height: 7vh;
  position: fixed;
  width: 100%;
  padding-right: 1vw;
  
  }
} */




@media only screen and (min-width: 460px) and (max-width: 900px) { 
  .Main-Container5 {
   
    display: flex;
    align-content: center ;
    justify-content:center;
    flex-direction: row;
 
 margin-top: 5vh;
 flex-wrap: wrap;
  }
  .Service {
 
    border-width: 1px;
    border-style: solid;
    border-color: rgb(133, 133, 133);
  
    width: 40vw;
   margin: 2.8rem;
   background: #6d6d6d1a;
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   transition: 0.3s;
  }
  .Service h1{
    font-size: 2.5rem;
    margin-top: 2vh;
  
  }
  .Service h2{
    font-size: 2rem;
    margin-top: 1vh;
    padding: 1rem 1.5rem  2rem  1.5rem;
  
  }
}

